import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Route, RouteComponentProps } from "react-router";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { CheckoutStateProvider } from "@capone/checkout";

import { ExperiencesBookConnectorProps } from "./container";
import {
  DesktopExperiencesBookWorkflow,
  MobileExperiencesBookWorkflow,
  ConfirmationPage,
} from "./components";
import { PATH_BOOK, PATH_BOOK_CONFIRMATION } from "../../utils/paths";
import "./styles.scss";
import { experiencesBookStateMachine } from "./state/stateMachine";
import { getInitialExperiencesMachineContext } from "./state/initialContext";
import { Actions } from "./state/actions";
import * as services from "./state/services";
import { Guards } from "./state/guards";
import { validateContext } from "./state/utils";

export interface IExperiencesBookProps
  extends RouteComponentProps,
    ExperiencesBookConnectorProps {}

export const ExperiencesBook = (props: IExperiencesBookProps) => {
  const { location } = props;

  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const history = useHistory();

  return (
    <CheckoutStateProvider
      stateMachine={experiencesBookStateMachine}
      actions={Actions}
      guards={Guards}
      context={getInitialExperiencesMachineContext()}
      services={services}
      getInitialContext={getInitialExperiencesMachineContext}
      validateContext={validateContext as (ctx: unknown) => boolean}
      history={history}
    >
      <Box
        className={clsx(
          "experiences-book-root",
          { confirm: location.pathname === PATH_BOOK_CONFIRMATION },
          { mobile: matchesMobile }
        )}
      >
        <Route path={PATH_BOOK} exact>
          <Box>
            {matchesDesktop && <DesktopExperiencesBookWorkflow />}
            {matchesMobile && <MobileExperiencesBookWorkflow />}
          </Box>
        </Route>
        <Route path={PATH_BOOK_CONFIRMATION}>
          <ConfirmationPage />
        </Route>
      </Box>
    </CheckoutStateProvider>
  );
};
