import React, { useContext, useRef, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import clsx from "clsx";
import {
  B2BSpinner,
  ButtonWrap,
  CheckoutStepper,
  Icon,
  IconName,
  LoadingIndicator,
} from "halifax";
import {
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";

import "./styles.scss";
import { PATH_BOOK_CONFIRMATION, PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import {
  CHANGE_CTA_TEXT,
  TRAVELERS_HEADING,
  HEADER_MAIN_TEXT,
  HEADER_SUB_TEXT,
  LOADING_TEXT,
  PAYMENT_HEADING,
  PAYMENT_SUBHEADING,
} from "./textConstants";
import {
  PriceBreakdown,
  TravelerSelection,
  TreesCard,
  ContactInfo,
  BookingInProgressModal,
  BookingErrorModal,
  ExperiencesRewardsAndPayment,
} from "..";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { getCheckoutSteps } from "./utils";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

export type DesktopExperiencesBookValidationError =
  | "flight-travelers"
  | "contact-info"
  | "rewards"
  | "card";

export const DesktopExperiencesBookWorkflow = () => {
  const clientContext = useContext(ClientContext);
  const history = useHistory();
  const [validationErrorTypes, setValidationErrorTypes] =
    React.useState<DesktopExperiencesBookValidationError[]>();

  const travelerSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);

  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const selectedTravelers = useCheckoutStateSelector(
    ExperiencesTravelerSelectors.getAllSelectedUserTravelers
  );

  const goToTravelers = () => {
    send(Event.GO_TO_TRAVELER_SELECT);
  };

  useEffect(() => {
    switch (parentState) {
      case ParentState.experiencesTravelerInformation:
        travelerSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.cardPayment:
        paymentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [parentState]);

  useEffect(() => {
    // update path so header becomes visible for confirmation
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  useEffect(() => {
    send({ type: Event.SET_PLATFORM, platform: "desktop" });
  }, []);

  return (
    <Box className="desktop-experiences-book-workflow-root">
      <CheckoutStepper
        steps={getCheckoutSteps(parentState)}
        headerCopy={HEADER_MAIN_TEXT}
        subHeaderCopy={HEADER_SUB_TEXT}
        logo={
          <ButtonWrap
            className="logo"
            onClick={() => {
              history.push(PATH_HOME);
            }}
          >
            {clientContext.logo}
          </ButtonWrap>
        }
        className="b2b combined-step"
      />

      <Box className="experiences-book-body-root">
        <Box className="experiences-book-body-wrapper">
          <Box className="experiences-book-body-left">
            <div
              className={clsx("experiences-book-section", {
                selected: [
                  ParentState.experiencesTravelerInformation,
                  ParentState.passport,
                  ParentState.contactInformation,
                ].includes(parentState),
              })}
              ref={travelerSectionRef}
            >
              <Box className="experiences-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="experiences-book-section-heading"
                >
                  {TRAVELERS_HEADING}
                </Typography>

                {parentState === ParentState.loading ? (
                  <LoadingIndicator
                    className="experiences-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                ) : [
                    ParentState.experiencesTravelerInformation,
                    ParentState.passport,
                    ParentState.contactInformation,
                  ].includes(parentState) ? (
                  <>
                    <hr />

                    <TravelerSelection
                      validationErrorTypes={validationErrorTypes}
                    />

                    <hr />

                    <ContactInfo
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : selectedTravelers.length ? (
                  <Box className="experiences-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="experiences-book-filled-section-content">
                      {selectedTravelers.reduce((nameString, person, i) => {
                        const firstLastName = `${person.givenName} ${person.surname}`;
                        if (i > 0) return `${nameString}, ${firstLastName}`;

                        return firstLastName;
                      }, "")}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![
                ParentState.experiencesTravelerInformation,
                ParentState.passport,
                ParentState.contactInformation,
              ].includes(parentState) &&
                !!selectedTravelers.length && (
                  <Box className="experiences-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("experiences-book-section", {
                selected: [
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState),
              })}
              ref={paymentSectionRef}
            >
              <Box className="experiences-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="experiences-book-section-heading"
                >
                  {PAYMENT_HEADING}
                </Typography>

                {parentState === ParentState.cartUpdate && (
                  <LoadingIndicator
                    className="experiences-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                )}

                {[
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState) ? (
                  <>
                    <Typography
                      variant="h4"
                      className="experiences-book-section-subheading"
                    >
                      {PAYMENT_SUBHEADING}
                    </Typography>
                    <hr />
                    <ExperiencesRewardsAndPayment
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : null}
              </Box>
            </div>

            <TreesCard />
          </Box>
          <Box className="experiences-book-body-right">
            <Box className="experiences-book-body-right-sticky-container">
              <PriceBreakdown
                setValidationErrorTypes={setValidationErrorTypes}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <BookingInProgressModal />
      <BookingErrorModal />
    </Box>
  );
};
