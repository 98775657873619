import { State } from "xstate";
import {
  getChildState,
  getNestedChildState,
  ParentState,
} from "@capone/checkout";

import { ExperiencesContext } from "../../../modules/book/state/types";
import {
  TravelerInformationState,
  TravelerInformationChildState,
} from "./types";

type TravelerStateType = State<ExperiencesContext>;
type TravelerStateWithoutValue = Pick<TravelerStateType, "context">;

export const getIsTravelerInformationLoading = (state: TravelerStateType) => {
  const childState = getChildState(state.value) as TravelerInformationState;
  const nestedChildState = getNestedChildState(
    state.value
  ) as TravelerInformationChildState;

  return (
    childState === TravelerInformationState.loading ||
    [
      TravelerInformationChildState.add,
      TravelerInformationChildState.delete,
      TravelerInformationChildState.update,
    ].includes(nestedChildState)
  );
};

export const getUserTravelers = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].userTravelers;

export const getSelectedTravelerIds = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].selectedTravelerIds;

export const getAllSelectedUserTravelers = (
  state: TravelerStateWithoutValue
) => {
  const selectedTravelerIds = getSelectedTravelerIds(state);
  const userTravelers = getUserTravelers(state);

  return userTravelers.filter((traveler) =>
    selectedTravelerIds.includes(traveler.id)
  );
};

export const getNumTravelerAlertDismissed = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].numTravelerAlertDismissed;

export const getCurrentUserTraveler = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].currentUserTraveler;

export const getOpenTravelerFormModal = ({ value }: TravelerStateType) =>
  TravelerInformationState.travelerForm ===
  (getChildState(value) as TravelerInformationState);

export const getTravelerErrorOpen = ({ value }: TravelerStateType) => {
  const stateValue = getChildState(value);
  return TravelerInformationState.error === stateValue;
};

export const getTravelerError = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].error;

export const getTravelerVisited = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].visited;

export const getTravelerStateValidated = (state: TravelerStateWithoutValue) =>
  getSelectedTravelerIds(state).length > 0;
