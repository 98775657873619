export const HEADER_MAIN_TEXT = "Confirm and book";
export const HEADER_SUB_TEXT = "Enter all required details";

export const TRAVELERS_HEADING = "Step 1: Traveler information";
export const PAYMENT_HEADING = "Step 2: Rewards and payment";
export const PAYMENT_SUBHEADING =
  "Combine travel credits, a travel offer, rewards, and the payment method you add to book this trip.";

export const LOADING_TEXT = "Loading";

export const CHANGE_CTA_TEXT = "Change";
