import React from "react";
import { RewardsPrice } from "redmond";
import { formatRewards } from "../../state/utils";

export const EXPERIENCES_CONFIRMATION_HEADING =
  "Confirmed, your experience is booked!";
export const EXPERIENCES_CONFIRMATION_EARN = (
  earn?: RewardsPrice,
  email?: string
) => (
  <>
    {!!earn?.value ? (
      <>
        You’ve earned <strong>{formatRewards(earn)}</strong> by booking this
        experience!{" "}
      </>
    ) : (
      ""
    )}
    {email ? <>An email confirmation will be sent to {email}</> : ""}
  </>
);
export const EXPERIENCES_CONFIRMATION_CODE_TEXT = (confirmationCode: string) =>
  `Your confirmation is #${confirmationCode}`;
