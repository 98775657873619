import {
  CardPaymentEventTypes,
  CardPaymentServices,
  CartQuoteEventType,
  CartQuoteState,
  CartState,
  ContactEventType,
  GenericChildState,
  ParentState,
  PaymentInformationChildState,
  PaymentInformationState,
  RewardsPaymentEventTypes,
  WalletEventTypes,
  getCardPaymentMachine,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getReviewMachineState,
  getRewardsPaymentMachine,
  getWalletMachine,
  trackPollSuccess,
  trackPollFailure,
  RewardsPaymentSelectors,
  CardPaymentSelectors,
  WalletSelectors,
} from "@capone/checkout";
import { MachineConfig } from "xstate";

import { ActionTypes } from "./actions";
import { Event, TEvent } from "./events";
import { getInitialExperiencesMachineContext } from "./initialContext";
import { Services } from "./services";
import { ExperiencesMachineContext } from "./types";
import { GuardTypes } from "./guards";
import { getExperiencesTravelerMachine } from "../../../checkout";

export const experiencesMachineId = "capone-experiences-checkout";

const originalCardPaymentMachine = getCardPaymentMachine({
  parentMachineId: experiencesMachineId,
  nextState: `#${experiencesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
  previousState: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
});
const cardPaymentMachine = {
  ...originalCardPaymentMachine,
  states: {
    ...originalCardPaymentMachine.states,
    [PaymentInformationState.add]: {
      initial: PaymentInformationChildState.idle,
      states: {
        [PaymentInformationChildState.idle]: {},
        [PaymentInformationChildState.verify]: {
          invoke: {
            src: CardPaymentServices.verifyPayment,
            onDone: {
              actions: [ActionTypes.setPaymentMethodId],
              target: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
            },
            onError: {
              actions: ActionTypes.setPaymentError,
              target: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
            },
          },
        },
      },
    },
  },
  on: {
    ...originalCardPaymentMachine.on,
    [CardPaymentEventTypes.NEXT]: [
      {
        target: `#${experiencesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        cond: GuardTypes.isDesktop,
      },
      {
        target: `#${experiencesMachineId}.${ParentState.review}`,
        cond: GuardTypes.isMobile,
      },
    ],
    [CardPaymentEventTypes.PREVIOUS]: [
      {
        target: `#${experiencesMachineId}.${ParentState.contactInformation}`,
        cond: GuardTypes.isMobile,
      },
      {
        target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
        cond: GuardTypes.isDesktop,
      },
    ],
  },
};

export const experiencesBookStateMachine: MachineConfig<
  ExperiencesMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialExperiencesMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: experiencesMachineId,

  // Initial state
  initial: ParentState.loading,

  // State definitions
  states: {
    [ParentState.loading]: {
      initial: "fetchContactInfo",
      states: {
        fetchContactInfo: {
          invoke: {
            src: Services.fetchContactInfoService,
            onDone: {
              target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
              actions: ActionTypes.setContactInfo,
            },
            onError: {
              target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
            },
          },
        },
      },
    },
    [ParentState.experiencesTravelerInformation]: {
      ...getExperiencesTravelerMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartQuote}`,
        nextStateFromReview: `#${experiencesMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: experiencesMachineId,
        previousState: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
        onDone: [
          {
            target: `#${experiencesMachineId}.${ParentState.cartQuote}`,
          },
        ],
        onError: [
          {
            target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
          },
        ],
      }),
    },
    [ParentState.cartQuote]: {
      // entry: [ActionTypes.addFlightProduct],
      // TODO
      // entry: ActionTypes.addExperienceProduct
      ...getCartQuoteMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.wallet}`,
      }),
      // TODO
      // exit: [ActionTypes.checkForPriceChange],
    },
    [ParentState.wallet]: {
      ...getWalletMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartUpdate}`,
      }),
    },

    // Update cart with Products and Discounts
    [ParentState.cartUpdate]: {
      ...getCartUpdateMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cardPayment}`,
      }),
    },
    [ParentState.rewardsPayment]: {
      ...getRewardsPaymentMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cardPayment}`,
        previousState: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
      }),
    },
    [ParentState.cardPayment]: cardPaymentMachine,
    [ParentState.review]: {
      initial: GenericChildState.idle,
      states: {
        [GenericChildState.loading]: {
          //   invoke: {
          //     src: ServiceTypes.trackReviewDetails,
          //     onDone: `#${experiencesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //     onError: `#${experiencesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //   },
        },
        [GenericChildState.idle]: {},
      },
      ...getReviewMachineState({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${experiencesMachineId}.${ParentState.cardPayment}`,
      }),
    },
    [ParentState.cartUpdateBeforeFulfill]: {
      ...getCartUpdateMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      entry: [ActionTypes.setPaymentFulfillParams],
      ...getCartFulfillMachine<ExperiencesMachineContext>({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.bookingConfirmation}`,
        trackPollSuccessAction: trackPollSuccess({
          eventName: "complete_buy_experience",
          // trackProperties returned in pollfulfill response will be also added
          getAdditionalProperties: (context) => {
            return {
              total_card_amount_usd:
                CardPaymentSelectors.getTotalCardPaymentRequired({ context }),

              total_rewards_amount_usd:
                RewardsPaymentSelectors.getRewardsFiatAmountToApply({
                  context,
                })?.value || 0,

              credit_amt_redeemed: Math.abs(
                WalletSelectors.getTravelWalletCreditToApply({ context })
                  ?.amount.amount || 0
              ),
              credit_redeemed: !!WalletSelectors.getTravelWalletCreditToApply({
                context,
              })?.amount.amount,
            };
          },
          getAdditionalEncryptedProperties: (context) => {
            const encryptedProperties: string[] = [];

            const selectedOffer = WalletSelectors.getSelectedOffer({ context });

            if (selectedOffer?.trackingPropertiesV2?.encryptedProperties) {
              encryptedProperties.push(
                selectedOffer.trackingPropertiesV2.encryptedProperties
              );
            }

            return encryptedProperties;
          },
        }),
        trackPollFailureAction: trackPollFailure({
          eventName: "complete_buy_experience",
          // trackProperties returned in pollfulfill response will be also added
          getAdditionalProperties: (_, __) => {
            return {};
          },
        }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    [Event.OPEN_TRAVELER_PICKER]: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
    // wallet credit Payment Events,
    [WalletEventTypes.SET_SELECTED_OFFER]: {
      actions: ActionTypes.addDiscounts,
    },
    [WalletEventTypes.REMOVE_SELECTED_OFFER]: {
      actions: ActionTypes.removeDiscounts,
    },
    [WalletEventTypes.SET_CREDIT_AMOUNT_TO_APPLY]: {
      actions: ActionTypes.setCreditAmountToApply,
    },
    // Rewards Payment Events,
    [RewardsPaymentEventTypes.SET_SELECTED_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setSelectedRewardsAccountId,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_FIAT_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsFiatAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_PAYMENT_VISITED]: {
      actions: ActionTypes.setRewardsPaymentVisited,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_ACCOUNTS]: {
      actions: ActionTypes.setRewardsAccounts,
    },
    [RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setEarnValueForAccount,
    },
    // Card Payment Events
    [CardPaymentEventTypes.SET_SELECTED_PAYMENT_METHOD_ID]: {
      actions: ActionTypes.setSelectedPaymentMethodId,
    },
    [CardPaymentEventTypes.DELETE_PAYMENT_METHOD]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.delete}`,
    [CardPaymentEventTypes.OPEN_PAYMENT_FORM]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}`,
    [CardPaymentEventTypes.CLOSE_PAYMENT_FORM]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
    [CardPaymentEventTypes.VERIFY_PAYMENT_METHOD]: {
      actions: ActionTypes.setSpreedlyToken,
      target: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}.${PaymentInformationChildState.verify}`,
    },
    [CardPaymentEventTypes.ACKNOWLEDGE_PRICE_CHANGE]: {
      actions: ActionTypes.acknowledgePriceChange,
    },
    // Contact Information Events
    [ContactEventType.CHANGE]: {
      actions: ActionTypes.setContactInfo,
    },
    // Events below are used for browser navigation
    [Event.GO_TO_TRAVELER_SELECT]: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${experiencesMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_CART_QUOTE]: `#${experiencesMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${experiencesMachineId}.${ParentState.review}`,
    [Event.GO_TO_CARD_PAYMENT]: `#${experiencesMachineId}.${ParentState.cardPayment}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${experiencesMachineId}.${ParentState.bookingConfirmation}`,
    [Event.SET_PLATFORM]: {
      actions: ActionTypes.setPlatform,
    },
    [CartQuoteEventType.RETRY_QUOTE]: {
      target: `#${experiencesMachineId}.${CartState.cartQuote}.${CartQuoteState.route}`,
      actions: [ActionTypes.setQuoteRetries, ActionTypes.clearCartQuoteError],
    },
  },
};
