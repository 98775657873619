import {
  ChildMachineConfig,
  ParentState,
  ReviewGuardTypes,
  FlightPassengerServicesV2,
  FlightPassengerServiceTypesV2,
} from "@capone/checkout";

import { ActionTypes } from "./actions";
import {
  TravelerInformationState,
  TravelerInformationChildState,
} from "./types";
import { GuardTypes } from "./guards";
import { Event } from "../../../modules/book/state/events";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.experiencesTravelerInformation,
  initial: TravelerInformationState.loading,
  states: {
    [TravelerInformationState.loading]: {
      initial: TravelerInformationChildState.fetch,
      states: {
        [TravelerInformationChildState.fetch]: {
          invoke: {
            src: FlightPassengerServicesV2.fetchUserPassengersService,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.loading}.${TravelerInformationChildState.route}`,
              actions: [
                ActionTypes.setUserTravelers,
                ActionTypes.setSingleDefaultTraveler,
              ],
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.error}`,
              actions: ActionTypes.setTravelersError,
            },
          },
        },
        [TravelerInformationChildState.route]: {
          always: [
            {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}`,
              cond: GuardTypes.haveAnyTravelersLoaded,
            },
            {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}`,
            },
          ],
        },
      },
    },
    [TravelerInformationState.travelerPicker]: {
      initial: TravelerInformationChildState.idle,
      states: {
        [TravelerInformationChildState.idle]: {},
        [TravelerInformationChildState.delete]: {
          invoke: {
            src: FlightPassengerServiceTypesV2.deleteUserPassengersService,
            onDone: {
              actions: ActionTypes.handleDeleteTraveler,
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}.${TravelerInformationChildState.idle}`,
            },
            onError: {
              actions: ActionTypes.setTravelersError,
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.error}`,
            },
          },
        },
        [TravelerInformationChildState.validate]: {
          invoke: {
            src: (ctx: any, e: any) =>
              FlightPassengerServicesV2.validateUserPassengersService(ctx, e),
            onDone: [
              {
                actions: [ActionTypes.setValidated],
                target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}.${TravelerInformationChildState.idle}`,
              },
            ],
            onError: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.error}`,
              actions: [ActionTypes.dismissNumTravelerAlert],
            },
          },
        },
      },
      on: {
        [Event.SELECT_TRAVELER]: [
          {
            actions: [ActionTypes.resetValidated, ActionTypes.selectTraveler],
            target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}.${TravelerInformationChildState.validate}`,
            cond: GuardTypes.willHaveSelectedTravelers,
          },
          {
            actions: ActionTypes.selectTraveler,
          },
        ],
        [Event.NEXT]: {
          target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.validate}`,
          cond: GuardTypes.hasAnySelectedTravelers,
        },
      },
    },
    [TravelerInformationState.travelerForm]: {
      initial: TravelerInformationChildState.route,
      states: {
        [TravelerInformationChildState.route]: {
          always: [
            {
              cond: GuardTypes.hasCurrentUserTraveler,
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.edit}`,
            },
            {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.new}`,
            },
          ],
        },
        [TravelerInformationChildState.edit]: {
          invoke: {
            src: FlightPassengerServiceTypesV2.trackEditTravelers,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.idle}`,
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.idle}`,
            },
          },
        },
        [TravelerInformationChildState.new]: {
          invoke: {
            src: FlightPassengerServiceTypesV2.trackNewTraveler,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.idle}`,
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.idle}`,
            },
          },
        },
        [TravelerInformationChildState.idle]: {},
        [TravelerInformationChildState.update]: {
          invoke: {
            src: FlightPassengerServiceTypesV2.updateUserPassengerService,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}.${TravelerInformationChildState.validate}`,
              actions: [
                ActionTypes.setUserTravelers,
                ActionTypes.selectTraveler,
              ],
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.error}`,
              actions: ActionTypes.setTravelersError,
            },
          },
        },
      },
      on: {
        [Event.UPDATE_TRAVELER]: {
          target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}.${TravelerInformationChildState.update}`,
        },
      },
    },
    [TravelerInformationState.validate]: {
      invoke: {
        src: (ctx: any, e: any) =>
          FlightPassengerServicesV2.validateUserPassengersService(ctx, e),
        onDone: [
          {
            cond: ReviewGuardTypes.visitedReview,
            target: config.nextStateFromReview || ParentState.cartQuote,
            actions: ActionTypes.setValidated,
          },
          {
            target:
              config.nextState || ParentState.experiencesTravelerInformation,
            actions: ActionTypes.setValidated,
          },
        ],
        onError: {
          target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.error}`,
          actions: [
            ActionTypes.dismissNumTravelerAlert,
            ActionTypes.resetValidated,
          ],
        },
      },
    },
    [TravelerInformationState.error]: {},
  },
  on: {
    [Event.DELETE_TRAVELER]: {
      target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}.${TravelerInformationChildState.delete}`,
    },
    [Event.SET_CURRENT_TRAVELER]: {
      actions: ActionTypes.setCurrentTraveler,
    },
    [Event.CLEAR_TRAVELER_INFORMATION_ERROR]: {
      actions: ActionTypes.clearTravelerInformationError,
      target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}`,
    },
    [Event.OPEN_TRAVELER_PICKER]: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerPicker}`,

    [Event.OPEN_TRAVELER_FORM]: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}`,
    // [Event.OPEN_FORM_AND_SET_TRAVELER]: {
    //   actions: ActionTypes.setCurrentTraveler,
    //   target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.travelerForm}`,
    // },
    // [FlightPassengerEventTypesV2.OPEN_PASSPORT_FORM]: `#${config.parentMachineId}.${ParentState.passport}.${PassportState.idle}`,

    [Event.PREVIOUS]: {
      target: config?.previousState || ParentState.contactInformation,
    },
    // [Event.COMPLETE]: [
    //   {
    //     cond: GuardTypes.notValidated,
    //     target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}.${TravelerInformationState.validate}`,
    //   },
    //   {
    //     cond: ReviewGuardTypes.visitedReview,
    //     target: config.nextStateFromReview || ParentState.cartQuote,
    //   },
    //   {
    //     target: config.nextState || ParentState.experiencesTravelerInformation,
    //   },
    // ],
  },
});
