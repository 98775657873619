export enum TravelerInformationState {
  idle = "idle",
  loading = "loading",
  travelerPicker = "travelerPicker",
  travelerForm = "travelerForm",
  error = "error",
  validate = "validate",
}

export enum TravelerInformationChildState {
  idle = "idle",
  new = "new",
  edit = "edit",
  update = "update",
  delete = "delete",
  add = "add",
  validate = "validate",
  route = "route",
  fetch = "fetch",
}
