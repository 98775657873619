import { assign, DoneInvokeEvent } from "xstate";
import { PersonListSuccess } from "@b2bportal/air-booking-api";
import {
  getObjectKeysAsObject,
  ParentState,
  setContextWithKey,
} from "@capone/checkout";
import { PassengerError } from "redmond";

import { ExperiencesContext } from "../../../modules/book/state/types";
import {
  SelectTravelerEvent,
  setCurrentTravelerEvent,
} from "../../../modules/book/state/events";

export const actions = {
  selectTraveler: assign(
    (ctx: ExperiencesContext, event: SelectTravelerEvent) => {
      const { singleTravelerWorkflow } = event;
      const onUpdate = event?.data?.onUpdate || false;

      let travelerId: string;
      if (event.data) {
        travelerId = event.data.id;
      } else {
        travelerId = event.travelerId || "";
      }
      const selectedTravelerIds =
        ctx[ParentState.experiencesTravelerInformation].selectedTravelerIds;

      const newTravelerInfoCtx = {
        ...ctx[ParentState.experiencesTravelerInformation],
      };

      // Remove traveler from appropriate list if exists and action is not triggered onUpdate
      if (!onUpdate && selectedTravelerIds.includes(travelerId)) {
        const newSelectedTravelerIds = selectedTravelerIds.filter(
          (id) => travelerId !== id
        );
        newTravelerInfoCtx.selectedTravelerIds = newSelectedTravelerIds;
      } else {
        // If traveler not in either list, add to appropriate list
        const userTravelers =
          ctx[ParentState.experiencesTravelerInformation].userTravelers;
        const newTraveler = userTravelers.find(
          (traveler) => traveler.id === travelerId
        );
        if (!newTraveler) {
          return setContextWithKey(
            ctx,
            ParentState.experiencesTravelerInformation,
            newTravelerInfoCtx
          );
        }

        // Hacky way to ensure user can only select a single traveler.
        if (singleTravelerWorkflow) {
          newTravelerInfoCtx.selectedTravelerIds = [travelerId];
        } else {
          newTravelerInfoCtx.selectedTravelerIds = [
            ...selectedTravelerIds,
            travelerId,
          ];
        }
      }

      return setContextWithKey(
        ctx,
        ParentState.experiencesTravelerInformation,
        newTravelerInfoCtx
      );
    }
  ),

  setUserTravelers: assign(
    (ctx: ExperiencesContext, event: DoneInvokeEvent<PersonListSuccess>) =>
      setContextWithKey(
        ctx,
        `${ParentState.experiencesTravelerInformation}.userTravelers`,
        event.data?.value || event.data
      )
  ),

  handleDeleteTraveler: assign(
    (ctx: ExperiencesContext, event: DoneInvokeEvent<any>) => {
      const selectedTravelerIds =
        ctx[ParentState.experiencesTravelerInformation].selectedTravelerIds;
      const newSelectedTravelerIds = selectedTravelerIds.filter(
        (id) => event.data.deletedPassengerId !== id
      );
      ctx[ParentState.experiencesTravelerInformation].selectedTravelerIds =
        newSelectedTravelerIds;
      ctx[ParentState.experiencesTravelerInformation].userTravelers =
        event.data.userPassengers;
      return ctx;
    }
  ),

  setSingleDefaultTraveler: assign((ctx: ExperiencesContext) => {
    if (
      ctx[ParentState.experiencesTravelerInformation].userTravelers.length === 1
    ) {
      ctx[ParentState.experiencesTravelerInformation].selectedTravelerIds.push(
        ctx[ParentState.experiencesTravelerInformation].userTravelers[0].id
      );
    }
    return ctx;
  }),

  setCurrentTraveler: assign(
    (ctx: ExperiencesContext, event: setCurrentTravelerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.experiencesTravelerInformation}.currentUserTraveler`,
        event.traveler
      )
  ),

  dismissNumTravelerAlert: assign((ctx: ExperiencesContext) =>
    setContextWithKey(
      ctx,
      `${ParentState.experiencesTravelerInformation}.numTravelerAlertDismissed`,
      true
    )
  ),

  setTravelersError: assign(
    (ctx: ExperiencesContext, event: DoneInvokeEvent<PassengerError>) =>
      setContextWithKey(
        ctx,
        `${ParentState.experiencesTravelerInformation}.error`,
        event.data
      )
  ),

  clearTravelerInformationError: assign((ctx: ExperiencesContext) => {
    ctx[ParentState.experiencesTravelerInformation].error = undefined;
    return ctx;
  }),

  setTravelerVisited: assign((ctx: ExperiencesContext) =>
    setContextWithKey(
      ctx,
      `${ParentState.experiencesTravelerInformation}.visited`,
      true
    )
  ),

  setValidated: assign((ctx: ExperiencesContext) =>
    setContextWithKey(
      ctx,
      `${ParentState.experiencesTravelerInformation}.isValidated`,
      true
    )
  ),

  resetValidated: assign((ctx: ExperiencesContext) =>
    setContextWithKey(
      ctx,
      `${ParentState.experiencesTravelerInformation}.isValidated`,
      false
    )
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
