import { ExperiencesShopOptionResponse } from "redmond";
import { put } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import { fetchExperienceShopOptions } from "../../../api/v0/shop/fetchExperiencesShopOptions";

export function* fetchExperiencesShopOptionsSaga(
  action: actions.IFetchExperiencesShopOptions
) {
  try {
    const shopOptionsResponse: ExperiencesShopOptionResponse =
      yield fetchExperienceShopOptions(action.request);
    yield put(
      actions.setExperiencesShopOptions(shopOptionsResponse.breakdowns)
    );
  } catch (e) {
    yield put(actions.setExperiencesShopOptionsCallStateFailed());
    Logger.debug(e);
  }
}
