import { checkoutServices } from "../../../../../../packages-module/src/modules/book/state/services/common";

import {
  CartQuoteServices,
  CartUpdateServices,
  CartFulfillServices,
  getObjectKeysAsObject,
  ContactInformationServices,
  CardPaymentServices,
  FlightPassengerServicesV2,
} from "@capone/checkout";

export const Services: any = {
  ...CartFulfillServices,
  ...CartQuoteServices,
  ...CartUpdateServices,
  ...checkoutServices,
  ...ContactInformationServices,
  ...CardPaymentServices,
  ...FlightPassengerServicesV2,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
