import { createSelector } from "@reduxjs/toolkit";
import {
  ExperienceBadge,
  ExperiencesAgeBandEnum,
  ExperiencesCancellationSummary,
  SpecificAgeBand,
} from "redmond";

import { IStoreState } from "../../../../reducers/types";
import { IShopOverview } from "../../components/ShopOverview/component";
import { IShopWhatsIncludedProps } from "../../components/ShopWhatsIncluded/component";
import { IShopContactInformationProps } from "../../components/ShopContactInformation/component";
import { IShopWhatToExpectProps } from "../../components/ShopWhatToExpect/component";
import { IShopPhotoGalleryProps } from "../../components/ShopPhotoGallery/component";
import { IShopSimilar } from "../../components/ShopSimilar/component";
import { IShopCancellation } from "../../components/ShopCancellationPolicy/component";

export const getExperiencesShopCallState = (state: IStoreState) =>
  state.experiencesShop.experiencesShopCallState;

export const getSelectedExperienceId = (state: IStoreState) =>
  state.experiencesShop.selectedExperienceId;

export const getExperiencesShopDetails = (state: IStoreState) =>
  state.experiencesShop.experiencesShopDetails;

export const getTravelerCounts = (state: IStoreState) =>
  state.experiencesShop.travelerCounts;

export const getExperienceId = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return null;

    return experiencesShopDetails.id;
  }
);

export const getExperienceName = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return null;

    return experiencesShopDetails.name;
  }
);

export const getExperienceProviderName = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return null;

    return experiencesShopDetails.supplierInfo.name;
  }
);

export const getExperiencesSearchLocation = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): string | null => {
    if (!experienceShopDetails) return null;

    return experienceShopDetails.searchLocation;
  }
);

export const getExperiencesShopOverview = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopOverview | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      title: experienceShopDetails.name,
      overview: experienceShopDetails.overview,
      rating: experienceShopDetails.rating,
      duration: experienceShopDetails.duration,
      locationLogistics: experienceShopDetails.logistics,
      language: experienceShopDetails.languageGuides
        ? experienceShopDetails.languageGuides[0].language
        : "English",
      provider: experienceShopDetails.supplierInfo.name,
      badges: experienceShopDetails.badges,
      bookableItems: experienceShopDetails.bookableItems,
      pricingCategory: experienceShopDetails.pricingCategory,
    };
  }
);

export const getExperiencesShopAgeBands = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return undefined;

    const parsedAgeBands: {
      [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
    } = {};

    experiencesShopDetails.ageBands.map((ageBand) => {
      parsedAgeBands[ageBand.ageBand] = ageBand;
    });

    return parsedAgeBands;
  }
);

export const getExperiencesShopWhatsIncluded = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopWhatsIncludedProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      additionalInformation: experienceShopDetails.additionalInformation,
      knowBeforeYouBook: experienceShopDetails.knowBeforeYouBook,
      inclusionsAndExclusions: experienceShopDetails.inclusionsAndExclusions,
    };
  }
);

export const getExperiencesShopCancellationPolicy = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopCancellation | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      cancellationPolicy: experienceShopDetails.cancellationPolicy,
    };
  }
);

export const getExperiencesShopContactInfo = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopContactInformationProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      supplierEmail: experienceShopDetails.supplierInfo.email,
      supplierPhone: experienceShopDetails.supplierInfo.phone,
    };
  }
);

export const getExperiencesShopWhatToExpect = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopWhatToExpectProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      itinerary: experienceShopDetails.itinerary,
    };
  }
);

export const getSelectedExperiencePriceBreakdown = (state: IStoreState) =>
  state.experiencesShop.selectedExperiencePriceBreakdowns;

// export const getExperiencesShopBookableItemsToDisplay = createSelector(
//   getExperiencesShopDetails,
//   (
//     experiencesShopDetails
//   ): {
//     experienceDailyPrice: ExperienceDailyPrice;
//     startTimes: string[];
//   } | null => {
//     if (!experiencesShopDetails) return null;

//     const bookableItems = experiencesShopDetails.bookableItems;
//     const dailyPrices = experiencesShopDetails.dailyPrices;

//     const parsedBookableItems = [];
//     dailyPrices.slice(0, 4).map((dailyPrice) => {
//       bookableItems.map(() => {

//       })
//     })
//     return null;
//   }
// );

export const getExperiencesShopGallery = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopPhotoGalleryProps | null => {
    if (!experienceShopDetails) return null;

    const { coverImage, additionalImages, name, duration, rating, badges } =
      experienceShopDetails;
    const providerPhotos = [coverImage, ...additionalImages];
    // TODO update travelerPhoto source
    const travelerPhotos = [
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/88/7b/95.jpg",
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/06/ef/71/79.jpg",
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/a9/2f/fd.jpg",
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/88/7b/e9.jpg",
    ];

    return {
      isSkeleton: false,
      providerPhotos,
      travelerPhotos,
      experienceName: name,
      duration,
      rating,
      isFreeCancellation: badges.includes(ExperienceBadge.FreeCancellation),
    };
  }
);

export const getExperiencesShopSimilar = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopSimilar | null => {
    if (!experienceShopDetails) return null;

    const {
      coverImage,
      additionalImages,
      name,
      duration,
      rating,
      badges,
      similarExperiences,
      pricingCategory,
      bestPrice,
      id,
      languageGuides,
      itinerary,
    } = experienceShopDetails;

    return {
      isSkeleton: false,
      selectedExperienceInfo: {
        name,
        rating,
        duration,
        coverImage,
        additionalImages,
        cancellationSummary: badges.includes(ExperienceBadge.FreeCancellation)
          ? ExperiencesCancellationSummary.FreeCancellation
          : ExperiencesCancellationSummary.PaidCancellation,
        pricingCategory,
        bestPrice,
        id,
        languageGuides,
        itinerary,
      },
      similarExperiences,
    };
  }
);
