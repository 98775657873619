import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, useDeviceTypes } from "halifax";
import {
  useCheckoutStateSelector,
  ContactSelectors,
  cartFulfillSelectors,
} from "@capone/checkout";

import "./styles.scss";
import {
  EXPERIENCES_CONFIRMATION_CODE_TEXT,
  EXPERIENCES_CONFIRMATION_EARN,
  EXPERIENCES_CONFIRMATION_HEADING,
} from "./textConstants";

import { postBookingSurveyPageId } from "./utils";
// import {
//   AVAILABLE,
//   getExperimentVariant,
//   TREES_MODAL_EXPERIMENT,
//   useExperiments,
// } from "../../../../context/experiments";
// import { PackageTreesCard } from "..";

export const ConfirmationPage = () => {
  const { matchesMobile } = useDeviceTypes();

  // TODO: does Hopper Tree card needs to be behind the experiment?
  // const expState = useExperiments();
  // const treesModalExperiment = getExperimentVariant(
  //   expState.experiments,
  //   TREES_MODAL_EXPERIMENT
  // );
  // const isTreesExperiment = treesModalExperiment === AVAILABLE;

  const earnedRewards = useCheckoutStateSelector(
    cartFulfillSelectors.getFulfillEarnedRewards
  );
  const emailAddress = useCheckoutStateSelector(
    ContactSelectors.getEmailAddress
  );

  // TODO get real confirmation code
  const confirmationCode = "0110345";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className={clsx("experiences-confirmation-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="experiences-confirmation-header">
        <Box className="check-mark-icon">
          <Icon name={IconName.ConfirmationCheckMark} aria-hidden />
        </Box>
        <Typography
          variant="h4"
          className="experiences-confirmation-heading-text"
        >
          {EXPERIENCES_CONFIRMATION_HEADING}
        </Typography>
      </Box>
      <Box className="experiences-confirmation-earn-info-container">
        <Typography className="earn-string">
          {EXPERIENCES_CONFIRMATION_EARN(earnedRewards, emailAddress)}
        </Typography>
        <Typography className="confirmation-code">
          {EXPERIENCES_CONFIRMATION_CODE_TEXT(confirmationCode)}
        </Typography>
      </Box>

      <div id={postBookingSurveyPageId()} />

      {/* {isTreesExperiment ? <PackageTreesCard isConfirmationScreen /> : null} */}
    </Box>
  );
};
