import { ParentState } from "@capone/checkout";
import { CheckoutStepperProps } from "halifax/build/CheckoutStepper/component";

export const getCheckoutSteps = (
  parentState: ParentState
): CheckoutStepperProps["steps"] => {
  return [
    {
      name: "Traveler information",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Rewards & payment",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.cartQuote:
            return 0;
          case ParentState.cardPayment:
            return 1;
          default:
            return 2;
        }
      })(),
    },
  ];
};
