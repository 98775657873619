import {
  CardPaymentActions,
  CartActions,
  CartQuoteActions,
  ContactInformationActions,
  ReviewActions,
  RewardsPaymentActions,
  WalletActions,
  getObjectKeysAsObject,
} from "@capone/checkout";
import { setPaymentFulfillParams, setPlatform } from "./common";
import { ExperiencesTravelerActions } from "../../../../checkout";

export const Actions: any = {
  ...CartActions,
  ...CartQuoteActions,
  ...ReviewActions,
  ...CardPaymentActions,
  ...WalletActions,
  ...RewardsPaymentActions,
  ...ContactInformationActions,
  ...ExperiencesTravelerActions,

  // common actions
  setPaymentFulfillParams,
  setPlatform,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
