import { assign } from "xstate";
import { ParentState } from "@capone/checkout";
import { ExperiencesMachineContext } from "../types";
import { SetPlatformEvent } from "../events";
import { getOpaquePayments } from "../selectors/common";

export const setPaymentFulfillParams = assign(
  (ctx: ExperiencesMachineContext) => {
    const opaquePayments = getOpaquePayments({ context: ctx });

    ctx[ParentState.cartFulfill].fulfillRequestPayments?.push(
      ...opaquePayments
    );

    return ctx;
  }
);

export const setPlatform = assign(
  (ctx: ExperiencesMachineContext, event: SetPlatformEvent) => {
    ctx.platform = event.platform;

    return ctx;
  }
);
