import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { ExperiencesBook } from "./component";

const mapStateToProps = (_state: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesBookConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperiencesBook = withRouter(connector(ExperiencesBook));
