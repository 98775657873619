import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CallState } from "redmond";

import { ExperiencesShop } from "./component";
import { IStoreState } from "../../reducers/types";
import { getFromDate, getLocation, getUntilDate } from "../search/reducer";
import "./reducer";
import {
  fetchExperiencesShop,
  fetchExperiencesShopOptions,
  fetchExperiencesShopReviews,
  setTravelerCounts,
  setSelectedExperience,
} from "./actions/actions";
import {
  getExperiencesShopOverview,
  getExperiencesShopWhatsIncluded,
  getExperiencesShopCallState,
  getExperiencesShopDetails,
  getExperiencesShopContactInfo,
  getExperiencesShopWhatToExpect,
  getExperienceName,
  getExperienceProviderName,
  getExperienceId,
  getExperiencesShopGallery,
  getSelectedExperienceId,
  getExperiencesShopSimilar,
  getExperiencesShopCancellationPolicy,
  getExperiencesSearchLocation,
  getExperiencesShopAgeBands,
  getTravelerCounts,
} from "./reducer";
import { setFromDate, setUntilDate } from "../search/actions/actions";
import { getRewardsAccountWithLargestValue } from "../rewards/reducer";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getLocation(state),
  locationLabel: getExperiencesSearchLocation(state),
  experienceId: getExperienceId(state),
  experienceName: getExperienceName(state),
  experienceProviderName: getExperienceProviderName(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  isLoading: getExperiencesShopCallState(state) === CallState.InProcess,
  shopDetails: getExperiencesShopDetails(state),
  shopOverview: getExperiencesShopOverview(state),
  shopWhatsIncluded: getExperiencesShopWhatsIncluded(state),
  shopContactInfo: getExperiencesShopContactInfo(state),
  shopWhatToExpect: getExperiencesShopWhatToExpect(state),
  shopGallery: getExperiencesShopGallery(state),
  selectedExperienceId: getSelectedExperienceId(state),
  shopSimilar: getExperiencesShopSimilar(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  shopCancellationPolicy: getExperiencesShopCancellationPolicy(state),
  ageBands: getExperiencesShopAgeBands(state),
  travelerCounts: getTravelerCounts(state),
});

const mapDispatchToProps = {
  setSelectedExperience,
  fetchExperiencesShop,
  setFromDate,
  setUntilDate,
  fetchExperiencesShopReviews,
  fetchExperiencesShopOptions,
  setTravelerCounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperiencesShop = withRouter(connector(ExperiencesShop));
