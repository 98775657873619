import React, { useContext } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
} from "halifax";
import {
  IPerson,
  PersonId,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
} from "redmond";
import clsx from "clsx";
import "./styles.scss";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import {
  FlightPassengerEventTypes,
  getParentState,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutStateSelector as useSelector,
  useCheckoutState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { PriceBreakdown } from "..";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { DesktopExperiencesBookValidationError } from "../DesktopExperiencesBookWorkflow";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

const getTravelerWorkflowStep = ({
  openTravelerFormModal,
  isMobile,
  parentState,
}: {
  openTravelerFormModal: boolean;
  isMobile: boolean;
  parentState: ParentState;
}): TravelerSelectStep => {
  switch (true) {
    case openTravelerFormModal:
      return TravelerSelectStep.TravelerInfoForm;
    case isMobile && parentState !== ParentState.review:
      return TravelerSelectStep.TravelerSelect;
    default:
      return TravelerSelectStep.Main;
  }
};

export interface ITravelerSelectionProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopExperiencesBookValidationError[];
  showNonModalContent?: boolean;
}

export const TravelerSelection = ({
  isMobile,
  validationErrorTypes,
  showNonModalContent,
}: ITravelerSelectionProps) => {
  const history = useHistory();
  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const openTravelerFormModal = useSelector(
    ExperiencesTravelerSelectors.getOpenTravelerFormModal
  );

  const travelers = useSelector(ExperiencesTravelerSelectors.getUserTravelers);
  const selectedTravelerIds = useSelector(
    ExperiencesTravelerSelectors.getSelectedTravelerIds
  );

  const travelersLoading = useSelector(
    ExperiencesTravelerSelectors.getIsTravelerInformationLoading
  );

  const hasError = useSelector(
    ExperiencesTravelerSelectors.getTravelerErrorOpen
  );

  const currentTraveler = useSelector(
    ExperiencesTravelerSelectors.getCurrentUserTraveler
  );

  const selectedTravelerCount = useSelector(
    ExperiencesTravelerSelectors.getSelectedTravelerIds
  ).length;

  const searchedGuestCount = 10;

  const numTravelerAlertDismissed = useSelector(
    ExperiencesTravelerSelectors.getNumTravelerAlertDismissed
  );

  const expState = useExperiments();

  const handleSelectTraveler = (
    travelerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (hasError) {
      send(Event.CLEAR_TRAVELER_INFORMATION_ERROR);
    }

    const selectedTraveler = travelers.find((p) => p.id === travelerId);
    if (selectedTraveler) {
      send({
        type: Event.SET_CURRENT_TRAVELER,
        traveler: selectedTraveler as IPerson,
      });
    }
    send({
      type: Event.SELECT_TRAVELER,
      travelerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdateTraveler = (traveler: IPerson) => {
    send({
      type: Event.UPDATE_TRAVELER,
      person: {
        ...traveler,
        // validation will fail if any of these fields are empty so include all or nothing
        passport:
          traveler.passport?.countryOfIssue &&
          traveler.passport?.expiration &&
          traveler.passport?.number
            ? traveler.passport
            : undefined,
      },
      onUpdate: true,
    });
  };

  const handleDeleteTraveler = (personId: string) => {
    send({ type: Event.DELETE_TRAVELER, personId });
  };

  const handleEditClick = (traveler: IPerson) => {
    if (parentState === ParentState.review) {
      // edit travelers from mobile review screen
      send({
        type: FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER,
        passenger: traveler,
      });
    } else {
      send({
        type: Event.SET_CURRENT_TRAVELER,
        traveler,
      });
      send(Event.OPEN_TRAVELER_FORM);
    }
  };

  const handleFormClose = () => {
    send(Event.OPEN_TRAVELER_PICKER);
  };

  const handleClickAddNewTraveler = () => {
    send({
      type: Event.SET_CURRENT_TRAVELER,
      traveler: undefined,
    });
    send(Event.OPEN_TRAVELER_FORM);
  };

  const selectedTravelerCountExceedsRoomCapacity = selectedTravelerCount;
  const selectedTravelerCountLessThanSearched =
    !numTravelerAlertDismissed && selectedTravelerCount < searchedGuestCount;

  const canContinue = isMobile
    ? !(
        selectedTravelerCountExceedsRoomCapacity ||
        selectedTravelerCountLessThanSearched
      )
    : true;

  const handleContinue = () => {
    if (canContinue) {
      send(Event.NEXT);
    } else {
      if (selectedTravelerCountExceedsRoomCapacity) {
        send({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SelectedPassengersExceedRoomCapacity,
            data: {},
          },
        });
      } else if (selectedTravelerCountLessThanSearched) {
        send({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
            data: {},
          },
        });
      }
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const { sessionInfo } = useContext(ClientContext);

  return (
    <>
      {travelersLoading ? (
        <LoadingIndicator
          className="flight-book-passenger-selection-loading-indicator"
          indicatorSize="small"
          indicator={B2BSpinner}
          message={constants.UPDATE_TEXT}
        />
      ) : (
        <TravelerSelectWorkflow
          showAdditionalInfoSection
          showFrequentFlyerSection
          showGenderField
          showNationalityField
          className={clsx("experiences-traveler-selection-root", {
            error: validationErrorTypes?.includes("flight-travelers"),
          })}
          travelers={travelers.map(personToIPerson)}
          progress={getTravelerWorkflowStep({
            openTravelerFormModal,
            isMobile: !!isMobile,
            parentState,
          })}
          setProgress={() => {}} // progress is derived from state machine
          userInfo={sessionInfo?.userInfo}
          titles={{
            travelerInfoTitle:
              isMobile && parentState === ParentState.review
                ? constants.TRAVELER_INFO_TEXT
                : constants.TRAVELER_INFO_TITLE_UPDATED,
            travelerInfoSubtitle: isMobile
              ? undefined
              : constants.TRAVELER_INFO_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
            editTravelerTitle: constants.EDIT_TRAVELER_TEXT,
            travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
            travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
            passportTitle: constants.PASSPORT_TITLE,
            passportSubtitle: constants.PASSPORT_SUBTITLE,
            passportSubtitle2: constants.PASSPORT_SUBTITLE_2,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          selectedTravelerIds={selectedTravelerIds}
          handleSelectTraveler={handleSelectTraveler}
          handleUpdatePassenger={handleUpdateTraveler}
          handleDeletePassenger={handleDeleteTraveler}
          isMobile={isMobile}
          buttonClassName="b2b"
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          isFlights
          showPassportSection
          requireNationality
          onClickAddNewTraveler={() => {
            handleClickAddNewTraveler();
            trackEvent({
              eventName: CLICKED_ADD_TRAVELER,
              properties: addTrackingProperties(expState.trackingProperties, {
                entry_type: "checkout",
              } as ClickedAddTravelerProperties),
            });
          }}
          onClickEditTraveler={(traveler) => handleEditClick(traveler)}
          tenant={config.TENANT}
          trackEvent={trackEvent}
          setSelectedTravelerIds={() => {}}
          editPassenger={
            currentTraveler ? personToIPerson(currentTraveler) : undefined
          }
          onTravelerFormClose={handleFormClose}
          onContinue={isMobile ? handleContinue : undefined}
          onGoBack={isMobile ? handleGoBack : undefined}
          selectionScreenHeaderElement={<PriceBreakdown isMobile dropdown />}
          mobileTravelerRowType="checkbox"
          updatedDesign
          customHeader={
            <Box className="experiences-flight-book-passenger-mobile-header">
              <Typography variant="h3" className="pax-header-primary-title">
                {constants.WHOS_FLYING_HEADING}
              </Typography>
              <hr />
              <Typography variant="h4" className="pax-header-secondary-title">
                {constants.TRAVELER_INFO_TITLE_UPDATED}
              </Typography>
              <Typography className="pax-header-subtitle">
                {constants.TRAVELER_INFO_SUBTITLE}
              </Typography>
            </Box>
          }
          showMobilePopoverTransition={false}
          showMobileChangeCTA={false}
          bypassSelectForSingleTraveler={false}
          showNonModalContent={showNonModalContent}
        />
      )}
    </>
  );
};
