import {
  CartGuards,
  ContactInformationGuards,
  ReviewGuards,
  getObjectKeysAsObject,
  CardPaymentGuards,
  WalletGuards,
  RewardsPaymentGuards,
} from "@capone/checkout";
import { ExperiencesCommonGuards } from "./common";
import { ExperiencesTravelerGuards } from "../../../../checkout";

export const Guards = {
  ...CartGuards,
  ...ContactInformationGuards,
  ...ReviewGuards,
  ...CardPaymentGuards,
  ...RewardsPaymentGuards,
  ...WalletGuards,
  ...ExperiencesCommonGuards,
  ...ExperiencesTravelerGuards,
};

export const GuardTypes = getObjectKeysAsObject(Guards);
