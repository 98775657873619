import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ContactInfoForm,
  ContactInfoStep,
  ContactInfoWorkflow,
  IContactInfo,
} from "halifax";
import { ITravelerStepErrors } from "redmond";
import {
  ContactSelectors,
  FlightPassengerEventTypes,
  GenericChildState,
  getChildState,
  getParentState,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import "./styles.scss";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import {
  CONTACT_INFO_NOTICE,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_TITLE_MOBILE,
  CONTINUE_TEXT,
  SAVING_TEXT,
} from "./textConstants";
import { PriceBreakdown } from "../PriceBreakdown";
import { DesktopExperiencesBookValidationError } from "../DesktopExperiencesBookWorkflow";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

export interface ContactInfoProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopExperiencesBookValidationError[];
  setValidationErrorTypes?: (
    types: DesktopExperiencesBookValidationError[]
  ) => void;
  contactFormOnly?: boolean;
}

export const ContactInfo = ({
  isMobile,
  validationErrorTypes,
  setValidationErrorTypes,
  contactFormOnly,
}: ContactInfoProps) => {
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });

  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const contactInfo = useSelector(ContactSelectors.getContactInformation);

  const contactInfoLoading = useSelector(
    ContactSelectors.getContactInformationLoading
  );

  const travelersValidated = useSelector(
    ExperiencesTravelerSelectors.getTravelerStateValidated
  );
  const selectedTravelerCount = useSelector(
    ExperiencesTravelerSelectors.getSelectedTravelerIds
  ).length;

  // TODO how to handle if user selected less traveler than searched?
  const searchedGuestCount = 10;

  const contactInfoValidated = useSelector(
    ContactSelectors.getContactInformationValidated
  );

  const numTravelerAlertDismissed = useSelector(
    ExperiencesTravelerSelectors.getNumTravelerAlertDismissed
  );

  const selectedTravelerCountLessThanSearched =
    selectedTravelerCount > 0 &&
    !numTravelerAlertDismissed &&
    selectedTravelerCount < searchedGuestCount;

  const canContinue =
    isMobile ||
    (!selectedTravelerCountLessThanSearched &&
      travelersValidated &&
      contactInfoValidated);

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
      send(Event.NEXT);
    } else {
      if (selectedTravelerCountLessThanSearched) {
        send({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
            data: {},
          },
        });
      } else {
        if (!travelersValidated && !contactInfoValidated) {
          setValidationErrorTypes?.(["flight-travelers", "contact-info"]);
        } else if (!travelersValidated) {
          setValidationErrorTypes?.(["flight-travelers"]);
        } else if (!contactInfoValidated) {
          setValidationErrorTypes?.(["contact-info"]);
        }
      }
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  const onContactInfoChange = (contactInfo: IContactInfo) => {
    send({ type: Event.CHANGE, contactInfo });
  };

  const goToContactInfo = () => [
    send({ type: Event.GO_TO_CONTACT_INFORMATION }),
  ];

  useEffect(() => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
    }
  }, [canContinue]);

  useEffect(() => {
    if (travelersValidated) {
      setValidationErrorTypes?.(
        validationErrorTypes?.filter((error) => error !== "flight-travelers") ||
          []
      );
    }
  }, [travelersValidated]);

  useEffect(() => {
    if (contactInfoValidated) {
      setValidationErrorTypes?.(
        validationErrorTypes?.filter((error) => error !== "contact-info") || []
      );
    }
  }, [contactInfoValidated]);

  if (isMobile)
    return (
      <ContactInfoWorkflow
        titles={{
          contactInfoTitle: CONTACT_INFO_TITLE_MOBILE,
          contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
          buttonTitle: CONTINUE_TEXT,
        }}
        progressStep={
          parentState === ParentState.review
            ? ContactInfoStep.Main
            : ContactInfoStep.ContactInfoForm
        }
        setProgressStep={(step) => {
          if (parentState === ParentState.review) {
            switch (step) {
              case ContactInfoStep.ContactInfoForm:
                goToContactInfo();
                break;
              case ContactInfoStep.Main:
              default:
                break;
            }
          }
        }}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        isMobile
        onContinueClick={handleContinue}
        onGoBack={handleBack}
        className="experiences-contact-info-workflow-root"
        mobileHeaderElement={<PriceBreakdown isMobile dropdown />}
        customHeader={
          <Box className="experiences-book-mobile-contact-info-header">
            <Typography
              className="mobile-contact-info-header-title"
              variant="h3"
            >
              {CONTACT_INFO_TITLE_MOBILE}
            </Typography>
            <Typography className="mobile-contact-info-header-subtitle">
              {CONTACT_INFO_SUBTITLE}
            </Typography>
            <hr className="heading-rule" />
          </Box>
        }
        showMobilePopoverTransition={false}
        contactFormOnly={contactFormOnly}
      />
    );

  return (
    <>
      <ContactInfoForm
        disabled={
          ![
            ParentState.contactInformation,
            ParentState.experiencesTravelerInformation,
          ].includes(parentState)
        }
        title={CONTACT_INFO_TITLE}
        subtitle={CONTACT_INFO_SUBTITLE}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        loading={contactInfoLoading}
        className={clsx("experiences-contact-info-form", {
          error: validationErrorTypes?.includes("contact-info"),
        })}
      />
      <Typography
        className={clsx("experiences-contact-info-notice", {
          error: validationErrorTypes?.some((error) =>
            ["contact-info", "flight-travelers"].includes(error)
          ),
        })}
      >
        {CONTACT_INFO_NOTICE}
      </Typography>
      <ActionButton
        onClick={handleContinue}
        message={
          childState === GenericChildState.saving ? SAVING_TEXT : CONTINUE_TEXT
        }
        className="experiences-continue-button"
        disabled={childState === GenericChildState.saving}
      />
    </>
  );
};
